// Import the license injector before mounting any react component
import './MUILicense.js'

import { TidalThemeProvider } from '@workwave-tidal/core/styles'
import { registerTidal } from '@workwave-tidal/license'
import { type ReactNode } from 'react'
import { themeConfig } from '../themeConfig.js'

type Props = { children: ReactNode }

registerTidal({
  env: 'development',
  product: 'CommcenterToolkitPreview',
})

// biome-ignore lint/style/noDefaultExport: this must have a default export
export default function MainDecorator(props: Props) {
  const { children } = props

  // TODO: connect the theme to a custom Theme config UI

  return (
    <>
      <TidalThemeProvider
        className="commcenter-story-main-decorator"
        theme={themeConfig}
        style={{ width: '100%', height: '100%' }}
      >
        {children}
      </TidalThemeProvider>
    </>
  )
}
